export const VARIABLES = {
  // for developer
  // url: "http://64.226.76.196:8020",
  // rms_login : "rms-nxgp",
  // rms_id : 2

  //for prod
  url: "https://rms-api.fluidscapes.in",
  fluidOpsURL: "https://fluidops.fluidscapes.in/",
  rms_login: "rms-fpcw",
  rms_id: 1,
  forgot_password_link: "https://rms-api.fluidscapes.in/reset-password/",
  forgot_password_verification_link: "https://rms-api.fluidscapes.in/reset-password-verification/",
};